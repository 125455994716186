.btn {
  font-family: "lexia", serif;
  font-size: 1em;
  font-style: regular;
  font-weight: normal;
  width: 100%;
  padding: 1em;
  display: block;
  text-align: center;
  text-decoration: none;
  transition: 0.3s all;
  &:hover {
    background-color: $middleblue;
  }
}

.btn-dark {
  background-color: $darkblue;
}

.btn.btn-dark,
.btn.btn-nav {
  padding: 1em !important;
}
.btn-nav {
  margin-top: vr(2);
  display: block;
  background-color: lighten($middleblue, 10%);
  &:hover {
    background-color: lighten($middleblue, 40%);
  }
}
