h1.logo {
  margin: 0;
  padding: 0;
}
header.banner {
  margin-bottom: vr(2);
  @include susy-media(large) {
    margin-bottom: 0;
    margin-top: vr(2);
  }
}
.brand img {
  max-width: 96%;
  margin: 0 auto;
  display: block;
}

.flexhead {
  display: flex;
  flex-direction: column;
}

#nav {
  order: -1;
  @include susy-media(large) {
    order: 0;
  }
}

