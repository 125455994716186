// HOMEPAGE

.home main h1 {
  margin-top: 0;
  @include typi($h3-map);
}

// main section {
//   padding: vr(2) vr(1);
// }

main {
  hyphens: auto;
}
main li {
  margin-left: vr(1);
}

main ul {
  padding-bottom: vr(1);
}

main section {
  @include susy-media(large) {
    padding: vr(2);
  }

  .textwrap {
    padding: vr(2) vr(1);
    @include susy-media(large) {
      padding: 0;
    }
  }
  .quote-button {
    padding: 0 vr(1) vr(2);
    @include susy-media(small) {
      padding: vr(2) vr(1);
    }
    @include susy-media(large) {
      padding: 0;
    }
  }

  .quote {
    padding: vr(2) vr(1) 0;
    @include susy-media(small) {
      padding: vr(2) vr(1);
    }
    @include susy-media(large) {
      padding: 0;
    }
  }
}

.entry-content {
  margin-top: 1.5rem;
}

.home main .benefits {
  background: transparent url("../images/background-logo-hell.png") 50% 50%
    no-repeat;
  @include susy-media(large) {
    background-position: 25% 25%;
  }
}
.home .bq {
  background-color: $darkblue;
  background-size: cover;
  background-repeat: no-repeat;
  // background-image: url("../images/books.jpg");
  min-height: 30vh;
  background-position: 50% 50%;
}

main blockquote {
  font-family: "lexia", serif;
  font-size: 1.1em;
  // text-shadow: 1px 1px 2px #000;
  padding: 0;
  margin: 1.5rem 0;
  &.sansserif {
    font-family: "Source Sans Pro", Verdana, sans-serif;
    font-size: 1em;
  }
  p {
    margin: 0;
    padding: 0;
  }
  footer {
    font-size: 70%;
    font-style: italic;
    font-family: "Source Sans Pro", Verdana, sans-serif;
  }
}

.wp-block-latest-posts__post-date {
  color: #fff;
}

@include susy-media(small) {
  .home .desc,
  .home .about-image {
    @include span(6 of 12);
  }

  .home .desc-image,
  .home .about {
    @include span(6 of 12 last);
  }

  .container-quote {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.desc-image {
  padding: 0.5em;
}
.flexfelder div {
  border: 2px solid $darkblue;
  background-color: $middleblue;
  padding: 1em;
}

// Tätigkeit
@include susy-media(large) {
  .flexfelder {
    display: flex;
    flex-wrap: wrap;

    div {
      flex-basis: 50%;
      flex-grow: 0;
    }
  }
}
