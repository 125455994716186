@include border-box-sizing;

html {
  @include typi-base();
  font-family: "Source Sans Pro", Verdana, sans-serif;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";
}

body {
  background-color: $white;
  border-top: 5px solid $darkblue;
}

img,
video,
object,
figure {
  max-width: 100%;
  height: auto;
}

iframe {
  max-width: 100%;
}

img {
  vertical-align: top;
}

.dark {
  background-color: $darkblue;
  color: $white;
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
}

.medium {
  background-color: $mediumblue;
  color: $white;
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
}

.clearfixer {
  @extend %clearfix;
}

.light {
  background-color: $white;
  color: $darkblue;
  a {
    color: $darkblue;
    &:hover {
      text-decoration: none;
    }
  }
}

h1 {
  @include typi($h1-map);
  margin: vr(1) 0 vr(1);
}

h1.topheading {
  margin-bottom: 0;
}

p.subheading {
  margin-top: 0;
  font-weight: 400;
}

h2,
.page-header h1 {
  @include typi($h2-map);
  margin: 0 0 1.5rem;
}

p,
ul {
  margin: vr(1) 0 vr(1);
}

ul,
li {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1200px) {
  .hide-mobile {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}

ol {
  padding-left: 0;
  @include susy-media(small) {
    padding-left: vr(1);
  }
  @include susy-media(large) {
    padding-left: vr(2);
  }
}
