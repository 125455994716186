.container {
  @include container(960px);
}

.container-content {
  @include container(780px);
}

.container-wide {
  @include container(1440px);
}
