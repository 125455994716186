// Reset Navigation

.navigation {
  @include full;
}

ul.menu {
}

.tiptop .nav h1, .tiptop .nav h1 a {
  display: block;
  margin: 0;
  overflow: hidden;
}

.nav h1 {
  font-size: 1em;
  display: none;
}

.nav li, .nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav {
  position: relative;
}

// Styling

.nav-primary {
  @extend %clearfix;
  ul {
    margin-top: vr(2);
  }
  ul ul {
    margin-top: 0;
  }
  ul ul li {
    background-color: lighten($middleblue, 60%);
  }
  ul ul li:nth-child(1) {
    margin-top: 0;
  }
  ul ul li:last-child {
    margin-bottom: 0;
  }
  ul ul li a {
    // padding-right: vr(1);
    &:after {
      content: ' —'
    }
  }
}

.nav-primary li:nth-child(1) {
  margin-top: vr(1);
}
.nav-primary li:last-child {
  margin-bottom: vr(2);
  @include susy-media(small) {
    margin-bottom: 0;
  }
}

.nav-primary li a {
  display: block;
  color: $darkblue;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.75em 1em 0.75em 0;
  border-bottom: 1px dotted lighten($darkblue, 50%);
  &:hover {
    text-decoration: underline;
  }
}

.tiptop .nav h1 a {
  position: absolute;
  right: 1em;
  top: 1em;
  background: $darkblue;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  display: block;
  text-indent: -1000em;
  z-index: 1000;
  font-size: 1em;
}

.tiptop .nav ul {
  max-height: 0;
  overflow: hidden;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  -webkit-transition: max-height .3s;
  transition: max-height .3s;
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  -webkit-transform: rotateZ(360deg);
  transform: translateZ(0);
}

.tiptop .nav li a {
  text-align: right;
  transition: transform .2s ease-out, opacity .2s;
  transform: scale(.8);
  opacity: 0;
}

.tiptop .nav li:nth-child(2) a {
    transition-delay: .03s;
}
.tiptop .nav li:nth-child(3) a {
    transition-delay: .05s;
}
.tiptop .nav li:nth-child(4) a {
    transition-delay: .07s;
}
.tiptop .nav li:nth-child(5) a {
    transition-delay: .09s;
}
.tiptop .nav li:nth-child(6) a {
    transition-delay: .12s;
}
.tiptop .nav li:nth-child(7) a {
    transition-delay: .14s;
}

.nav h1 a:before {
    top: -2em;
}

.nav h1 a:after, .open .nav h1 a:before {
    top: 0;
}

.open .nav h1 a:after {
    top: 100%;
}

.open .nav ul {
  max-height: 50em;
}

.open .nav h1 a {
    background-color: $middleblue;
}

.open .nav li a {
  opacity: 1;
  transform: scale(1);
}

.tiptop .nav h1 a:after, .tiptop .nav h1 a:before {
    background-position: 50% 50%;
    content: "";
    font-weight: 300;
    height: 2.5em;
    left: 0;
    line-height: 1;
    position: absolute;
    text-align: center;
    -moz-transition: top .25s,-moz-transform .25s;
    -ms-transition: top .25s,-ms-transform .25s;
    -o-transition: top .25s,-o-transform .25s;
    -webkit-transition: top .25s,-webkit-transform .25s;
    transition: top .25s,transform .25s;
    width: 100%;
}

.nav h1 a:before, .icon-nav-close {
  background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%221.5%22%20stroke-miterlimit%3D%2210%22%20d%3D%22M.539.509l10.984%2010.948M.557%2011.475L11.505.491%22%2F%3E%3C%2Fsvg%3E');background-repeat:no-repeat;
}

.nav h1 a:after, .icon-nav-open {
  background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2233%22%20height%3D%2212%22%20viewBox%3D%220%200%2033%2012%22%3E%3Ctitle%3EMEN%C3%9C%3C%2Ftitle%3E%3Cpath%20d%3D%22M4.313%2011l-1.89-6.16h-.049c.068%201.253.102%202.089.102%202.508V11H.988V3.147h2.267l1.858%206.005h.033l1.97-6.005h2.267V11H7.831V7.283c0-.175.003-.378.008-.607.005-.229.03-.838.073-1.826h-.049L5.838%2011H4.313zm11.57%200H11.36V3.147h4.522v1.365h-2.857v1.724h2.659V7.6h-2.66v2.025h2.858V11zm8.604%200H22.37l-3.416-5.94h-.049c.068%201.049.102%201.797.102%202.245V11h-1.487V3.147h2.1l3.41%205.882h.038a47.617%2047.617%200%200%201-.08-2.165V3.147h1.498V11zm8.368-7.853V8.23c0%20.58-.13%201.088-.39%201.525-.259.437-.634.772-1.125%201.004-.49.233-1.07.35-1.74.35-1.01%200-1.794-.26-2.352-.777-.559-.517-.838-1.225-.838-2.124v-5.06h1.66v4.808c0%20.605.121%201.049.365%201.332.243.283.646.424%201.208.424.544%200%20.94-.142%201.184-.427.246-.285.368-.731.368-1.34V3.147h1.66zm-5.054-1.509c0-.233.067-.412.201-.537.134-.125.316-.188.545-.188.237%200%20.422.066.556.199a.706.706%200%200%201%20.202.526.686.686%200%200%201-.204.518c-.136.131-.32.197-.554.197-.229%200-.41-.063-.545-.188-.134-.126-.201-.301-.201-.527zm2.164%200c0-.25.073-.434.218-.55a.835.835%200%200%201%20.54-.175c.232%200%20.418.065.556.193.137.13.206.307.206.532%200%20.219-.07.392-.21.521-.139.13-.323.194-.552.194a.835.835%200%200%201-.54-.175c-.145-.116-.218-.296-.218-.54z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
  background-repeat:no-repeat;
  top: 0em;
}

@include susy-media(small) {

  // btn
  .nav h1 {
    position: absolute;
    left: -1000em;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .nav-primary > ul {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
  }

  .nav-primary .menu li {
    position: relative;
    &:first-child {
      // reset mobile
      margin-top: 0;
    }
    &:last-child {
      // reset mobile
      margin-right: 0;
    }
    a {
      // reset mobile
      border-bottom: none;
      display: block;
      padding: 2em 0 1em;
    }
  }

  .nav-primary {
    .menu-item-has-children {
      position: relative;
    }
    .sub-menu {
      display: none;
      min-width: 400px;
      li {
        padding: 0;
        margin: 0;
        display: block;
        border-bottom: 1px solid $middleblue;
      }
      a {
        text-align: left!important;
        padding: 1em!important;
        &:after {
          content: '';
        }
      }
    }
    ul li:hover .sub-menu {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  .tiptop .nav ul {
    overflow: visible;
    max-height: inherit;
    padding: 0;
  }

  .tiptop .open .nav ul {
    margin-top: 0;
  }

  .tiptop .nav li a {
    opacity: 1;
    transform: scale(1);
  }
}
