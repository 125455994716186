@include susy-media(large) {
  .c1-2 {
    @include span(6 of 12);
  }
  .c1-2-last {
    @include span(6 of 12 last);
  }
}

div.wpcf7 {
    margin  : 0;
    padding : 0;
}

div.wpcf7-response-output {
    margin  : 2em 0 1em;
    padding : 1em;
    font-size: 90%;
}

div.wpcf7 .screen-reader-response {
    position : absolute;
    clip     : rect(1px, 1px, 1px, 1px);

    overflow : hidden;
    height   : 1px;
    width    : 1px;
    margin   : 0;
    padding  : 0;

    border   : 0;
}

div.wpcf7-mail-sent-ok {
    border : 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
    border : 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
    border : 2px solid #ffa500;
}

div.wpcf7-validation-errors {
    background-color : red;
}

.wpcf7-form-control-wrap {
    position : relative;
}

span.wpcf7-not-valid-tip {
    display   : block;

    font-size : .85em;
    color     : $lightblue;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
    position   : absolute;
    top        : 20%;
    left       : 20%;
    z-index    : 100;

    padding    : .2em .8em;

    background : #fff;
    border     : 1px solid #ff0000;
}

span.wpcf7-list-item {
    margin-left : 0.5em;
}

.wpcf7-display-none {
    display : none;
}

div.wpcf7 img.ajax-loader {
    vertical-align : middle;

    margin-left    : 4px;

    border         : none;
}

div.wpcf7 div.ajax-error {
    display : none;
}

div.wpcf7 .placeheld {
    color : #888;
}

.wpcf7 input[type="text"], .wpcf7 input[type="email"], .wpcf7 input[type="submit"], textarea {
    width              : 100%;
    margin-top         : .5em;
    padding            : .5em;

    background-color   : #fff;
    font-size          : inherit;
    color              : #000;
    border             : none;
    border-radius      : 0;

    -webkit-appearance : none;
}

.wpcf7 input[type="checkbox"] {
 margin-right: 10px;
}

.wpcf7 input[type="submit"] {
    padding          : 1em;
    font-family: "lexia", serif;
  font-size: 1em;
  font-style: regular;
  font-weight: normal;
    background-color : lighten($middleblue, 10%);
    font-weight      : 700;
    color            : #fff;
    text-transform   : uppercase;

    transition: .2s background-color;

    &:hover {
        background-color: lighten($middleblue, 40%);
        color: #fff;
    }
}
