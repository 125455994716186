$lightblue: rgba(8, 62, 107, 0.06);
$middleblue: #20638e;
$mediumblue: #11709f;
$darkblue: #022a92;
$white: #fff;

$susy: (
  columns: 12,
  global-box-sizing: border-box,
  gutters: 2/3
);

$susy-media: (
  small: 800px,
  large: 1200px
);

$breakpoints: (
  small: 800px,
  large: 1200px
);

$typi: (
  null: (
    18px,
    1.5
  ),
  small: (
    18px,
    1.5
  ),
  large: (
    20px,
    1.5
  )
);

$h1-map: (
  null: (
    3.129em,
    1.2
  ),
  small: (
    2.3353em,
    1.3
  ),
  large: (
    1.75em,
    1.3
  )
);

$h2-map: (
  null: 1.3353em,
  small: 1.769em,
  large: 1.769em
);

$h3-map: (
  null: 1.75em,
  small: 1.75em,
  large: 1.75em
);
