// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: 0 auto;
  height: auto;
}
.alignleft,
.alignright {
  display: block;
  margin: 0 auto vr(1);
  height: auto;
}

@include susy-media(small) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: vr(1);
  }
  .alignright {
    float: right;
    margin-left: vr(1);
  }
}

.wp-caption-text {
  font-size: 85%;
  font-style: italic;
}
