.content-info {
  color: $darkblue;
  padding: vr(2) vr(1);
  @include susy-media(large) {
    padding: 0;
  }
  h1 {
    font-size: 1.75rem;
  }
}

.article-list {
  @extend %clearfix;
  padding-bottom: 0;
}

.content-footer {
  color: white;
  padding: 0 vr(1);
  background-color: $darkblue;
  // .container {
  //   background-image:  url('../images/cnvc.png');
  //   background-repeat: no-repeat;
  //   background-position: 100% center;
  // }

  #menu-footer {
    display: flex;
    flex-wrap: wrap;
    padding: vr(1) 0;
  }

  li {
    list-style: none;
    background: none !important;
    padding-left: 0;
  }
  a {
    color: white;
    display: block;
    margin-right: vr(1);
    &:hover {
      text-decoration: none;
    }
  }
}

.preview {
  @include full;
  margin-bottom: vr(1);
  list-style: none;
}

.preview-image {
  @include span(3 of 12);
}
.preview-title {
  font-size: 1.052631579em;
  line-height: 1.3;
  margin: 0;
}

.more-left a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.more-left--heading {
  margin-top: 0 !important;
}

@include susy-media(large) {
  .more-left {
    @include span(5 of 12);
  }
  .more-right {
    @include span(6 of 12 last);
    margin-top: 42px;
  }
}
