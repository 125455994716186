.zu-meiner-person .textwrap li {
  background: transparent url('../images/person-bullet-empty.png') 0 50% no-repeat;
  padding-left: 2em;
  list-style-type: none;
  margin: 0;
  &:nth-child(3n+1) {
    background-image: url('../images/person-bullet.png');
  }
}

ol.dates {
  list-style: none;
  counter-reset: listCounter;
  position: relative;

  li {
    counter-increment: listCounter;
    margin-left: 3.5em;
    @include susy-media(small) {
      margin-left: 1.5em;
    }
    &::before {
      content: counter(listCounter,decimal-leading-zero);
      font-size: 2em;
      font-weight: 700;
      color: lighten(#20638e, 30);
      left: 0;
      line-height: 1.1;
      position: absolute;
    }
  }
}
