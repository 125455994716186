.updated {
  font-style: italic;
  font-size: 90%;
  margin-top: 0;
}

h1.entry-title {
  @include typi($h2-map);
  margin-bottom: 0;
  hyphens: auto;
}
